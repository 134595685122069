import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "DD.MM.YYYY")
        slug
        title
      }
    }
  }
`;

export default function Template({ data }) {
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className="bg-light-blue-50 w-full py-28 pt-36">
        <div className="container">
          <h1 className="heading-sm text-center">{frontmatter.title}</h1>
          <p className="sub-sm mx-auto max-w-2xl text-center mt-8">
            Sidst opdateret {frontmatter.date}
          </p>
        </div>
      </div>
      <div className="container max-w-4xl pt-14 pb-28">
        <div
          className="prose lg:prose-lg"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  );
}
